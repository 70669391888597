import * as React from 'react';
import { graphql } from 'gatsby';

import App from '../components/layout/App';
import Content from '../components/base/Content';
import ContentCenter from '../components/layout/ContentCenter';

interface LegalsProps {
  rest: any;
  data: {
    content: {
      edges: [
        {
          node: {
            data: {
              seo_title: string;
              content: {
                html: string;
              };
            };
          };
        }
      ];
    };
  };
}

const Legals: React.SFC<LegalsProps> = ({ data, ...rest }) => {
  const DATA = data.content.edges[0].node.data;
  const createContentMarkup = () => {
    return { __html: DATA.content.html };
  };
  return (
    <App title={DATA.seo_title} {...rest}>
      <ContentCenter>
        <Content dangerouslySetInnerHTML={createContentMarkup()} />
      </ContentCenter>
    </App>
  );
};
export default Legals;

export const pageLegals = graphql`
  query LegalsQuery {
    content: allPrismicMentionsLegales {
      edges {
        node {
          data {
            seo_title
            content {
              html
            }
          }
        }
      }
    }
  }
`;
